import styled, { css, createGlobalStyle } from 'styled-components'

export const Divider = styled.div`
  width: ${(props) => (props.width ? props.width : 'calc(100% - 40px)')};
  box-sizing: border-box;
  height: 1px;
  background-color: #dbdbdb;
  margin: ${(props) => (props.margin ? props.margin : '20px')};
`

export const CheckboxForm = styled.div<{
  margin?: string
  marginMobile?: string
}>`
  display: flex;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '10px 0 30px 0')};

  @media (max-width: 980px) {
    margin: ${(props) =>
      props.marginMobile ? props.marginMobile : '10px 0 30px 0'};
  }

  input[type='checkbox'] {
    margin-right: 10px;
  }

  .label-text {
    cursor: pointer;

    &::before {
      top: -1px;
    }

    &::after {
      top: 5px !important;
    }
  }

  &:focus-visible {
    outline: ${({ theme }) => theme.globals.focusVisible.outline};
    box-shadow: ${({ theme }) => theme.globals.focusVisible.boxShadow};
    transition: ${({ theme }) => theme.globals.focusVisible.transition};
  }
`

export const DocumentPicker = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '64px')};
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : '#F7F9FC')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '0')};
  padding: ${(props) => (props.padding ? props.padding : '20px')};
  box-sizing: border-box;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '20px'};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  &:active,
  &:focus {
    background-color: #f7f9fc;
  }

  &:hover {
    background-color: #f7f9fc;

    svg {
      color: ${(props) => props.iconHoverColor && props.iconHoverColor};
    }
  }

  &.current {
    border-color: ${(props) =>
      props.currentBorder ? props.currentBorder : '#F7F9FC'};
    background-color: ${(props) =>
      props.currentBg ? props.currentBg : 'transparent'};

    span {
      color: ${(props) => props.currentColor && props.currentColor};
    }

    svg {
      color: ${(props) => props.currentColor && props.currentColor} !important;
    }
  }

  span {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 400;
  }

  &:focus-visible {
    outline: ${({ theme }) => theme.globals.focusVisible.outline};
    box-shadow: ${({ theme }) => theme.globals.focusVisible.boxShadow};
    transition: ${({ theme }) => theme.globals.focusVisible.transition};
  }
`

export const DocumentChecked = styled.div`
  margin-left: auto;
  line-height: normal;
  width: 32px;
  height: 32px;
`

export const SelectHolder = styled.div`
  width: 100%;
  margin: 20px 0 30px;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  span {
    padding-bottom: 0%;
    font-size: 14px;
  }

  input {
    transition: all 0.3ms ease-in-out;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`

export const Condition = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  @media (max-width: 980px) {
    margin: 0px;
  }

  h3 {
    margin: 0 0 8px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
  }

  svg {
    margin-top: -2px;
  }

  .label-text {
    line-height: 21px;
  }
`

export const CountryWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 30px;

  span {
    color: rgb(80, 80, 80);
    margin-bottom: 8px;
  }

  .select-input {
    &__placeholder {
      cursor: pointer;
    }

    &__single-value {
      padding: 0;
      margin: 0;
      cursor: pointer;

      & > div {
        align-items: center;

        span {
          margin-bottom: 0;
        }
      }
    }

    &__dropdown-indicator {
      padding: 8px 13px;
      cursor: pointer;
    }

    &__clear-indicator {
      padding: 4px 13px;
      cursor: pointer;
    }

    &__input-container {
      cursor: pointer;
      margin: 0;
    }

    &__input {
      caret-color: transparent;
    }
  }
`

export const DocumentTypeWrap = styled.div`
  width: 100%;
  margin: 0 0 30px;

  @media (max-width: 980px) {
    border-top: ${(props) =>
      props.border ? `${props.border} !important` : '#280B60 !important'};
  }
`

export const DocumentTypeLabel = styled.div`
  color: rgb(80, 80, 80);
  margin: 0 0 30px 0px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`

export const PlaceOfBirthWrap = styled.div`
  width: 100%;
  margin: 0 0 30px;
  @media (max-width: 980px) {
    margin: 30px 0;
  }
`

export const Label = styled.div`
  color: rgb(80, 80, 80);
  margin: 30px 0 8px;
`

export const ButtonImgWrap = styled.div<{ $hide?: boolean }>`
  margin-right: 5px;
  margin-bottom: -2px;

  ${({ $hide }) =>
    $hide &&
    css`
      display: none;
    `}
`

export const ButtonText = styled.span`
  text-wrap: nowrap;
`

export const PdfWrap = styled.div`
  width: 100%;
  margin-top: 36px;
`

export const OnboardingList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
`

export const OnboardingItem = styled.div<{
  $className?: string
  fontSize?: string
}>`
  display: flex;
  align-items: center;

  span {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};
    font-weight: 500;
  }

  &.custom {
    gap: 16px;
    padding: 16px 24px 16px;
    background-color: rgba(247, 249, 252, 1);
    border-radius: 8px;

    span {
      line-height: 20px;
      font-weight: 600;
    }
  }
`

export const OnboardingIcon = styled.div<{ size?: string }>`
  display: inline-block;
  width: ${({ size }) => (size ? size : '58px')};
  height: ${({ size }) => (size ? size : '58px')};
`

export const OnboardingSingleLine = styled.span`
  display: flex;
  margin-left: 10px;
  width: 100%;
  height: 55px;
  align-items: center;
`

export const OnboardingDoubleLine = styled.span`
  margin-left: 10px;
  margin-top: 12px;
`

export const OnboardingItemHeading = styled.span`
  font-weight: 800 !important;
  width: 100%;
  display: inline-block;
`

export const KlarnaSignicatBranding = styled.div`
  position: absolute;
  bottom: 30px;
  right: 20px;
`

export const Subheading = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1em;
  line-height: 1.4em;
`

export const JitpayIntro = styled.div`
  position: relative;
  padding-bottom: ${(props) => {
    if (props.language === 'en') {
      return '0'
    } else if (props.language === 'es') {
      return '60px'
    } else if (props.language === 'de') {
      return ''
    }
  }};

  div {
    left: ${(props) => {
      if (props.language === 'en') {
        return '753px !important'
      } else if (props.language === 'es') {
        return '-4px !important'
      } else if (props.language === 'de') {
        return '87px !important'
      }
    }};
    bottom: ${(props) => {
      if (props.language === 'en') {
        return '-8px !important'
      } else if (props.language === 'es') {
        return '24px !important'
      } else if (props.language === 'de') {
        return '-8px !important'
      }
    }};

    @media (max-width: 980px) {
      left: ${(props) => {
        if (props.language === 'en') {
          return '123px !important'
        } else if (props.language === 'es') {
          return '112px !important'
        } else if (props.language === 'de') {
          return '0px !important'
        }
      }};
      bottom: ${(props) => {
        if (props.language === 'en') {
          return '-8px !important'
        } else if (props.language === 'es') {
          return '52px !important'
        } else if (props.language === 'de') {
          return '-33px !important'
        }
      }};
    }
  }

  @media (max-width: 980px) {
    margin-bottom: 50px;
  }
`

export const JitpayLogoContainer = styled.span`
  position: relative;
  display: inline-block;
  width: 72px;
  margin-left: 6px;
  bottom: 0 !important;
  left: 0 !important;

  svg {
    position: absolute;
    top: -23px;
    width: 100%;
  }

  @media (max-width: 980px) {
    margin-left: 2px;
    bottom: 0 !important;
    left: 0 !important;
  }
`

export const MicropaymentInfoGraph = styled.div`
  width: 100%;
  margin: 30px 0 30px 0;
`

export const MicropaymentInfoContent = styled.div`
  width: 100%;
  line-height: 1.6em;
  margin: 0 0 30px 0;
`

export const MicropaymentInfoContentWithBorder = styled.div`
  width: 100%;
  line-height: 1.6em;
  margin: 0 0 30px 0;
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : '1px solid #ebebeb'};
  padding-top: 30px;
  box-sizing: border-box;
`

export const MicropaymentWarn = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 20px);
  padding: 0 10px;
  border-radius: 4px;
  background-color: rgba(237, 238, 239, 1);
  min-height: 44px;
  line-height: 44px;
`

export const MicropaymentWarnInner = styled.div`
  line-height: 44px;
  min-height: 44px;
  display: flex;
  color: #4c4c4c;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;

  > :first-child {
    padding: 9px 0;
    margin-right: 4px;
  }
`

export const WipImage = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`

export const Subtitle = styled.h4`
  width: 100%;
  text-align: center;
  font-style: italic;
  margin: -10px 0 0 0;
  padding: 0;
`

export const FileUpload = styled.div`
  z-index: 0;
  width: 100%;
  border: ${(props) => (props.isFile ? 'none' : '1px dashed #9B9B9B')};
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  :hover {
    background-color: #f7f7f9;
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
  }

  &:focus-visible {
    outline: ${({ theme }) => theme.globals.focusVisible.outline};
    box-shadow: ${({ theme }) => theme.globals.focusVisible.boxShadow};
    transition: ${({ theme }) => theme.globals.focusVisible.transition};
  }
`

export const UploadTitle = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #34353a;
  margin: 10px 0 15px 0;
`

export const UploadDefault = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`

export const FileSize = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  line-height: 141.99%;
  margin-bottom: 30px;

  span {
    font-weight: 700;
    font-family: ${(props) => props.fontFamily};
  }
`

export const UnderlinedBold = styled.span`
  text-decoration: underline;
  font-weight: 700;
  font-family: ${(props) => props.fontFamily};
`

export const UnderlinedButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-family: inherit;
  color: ${(props) => (props.color ? props.color : '#2a0760')};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }
`

export const UploadIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-top: 15px;
`

export const FileInfo = styled.div`
  width: 100%;
  background: #edeeef;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
`

export const FileName = styled.span`
  font-size: 14px;
  overflow-wrap: anywhere;
`

export const FileDeselect = styled.span`
  display: flex;
  align-items: center;
  width: 19px;
  height: 19px;
  z-index: 10;
  cursor: pointer;
`

export const UploadParagraph = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 170%;
  max-width: 400px;
  margin: 0px auto;
`

export const UploadAddressParagraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  max-width: 400px;
  margin: 0px auto;
  font-family: ${(props) => props.fontFamily};
`

export const UploadTypeList = styled.ul`
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 141.99%; /* 17.039px */
  max-width: 400px;
  margin: 0px auto;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const FileWarning = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: #edeeef;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #34353a;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 30px;
`

export const FileError = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: #ffdcdc;
  border: 1px solid #ff8c8c;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #34353a;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 30px;
`

export const IntroKontomatikContent = styled.div`
  > :last-child {
    margin-bottom: 30px;
  }
`

export const IntroDocIcon = styled.div`
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const IntroInfo = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: #edeeef;
  display: flex;
  gap: 20px;
  align-items: center;
  color: #34353a;
  font-size: 14px;
  box-sizing: border-box;
  margin: 30px 0;
  font-size: 12px;
  line-height: 18px;

  ul {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: 24px;
  }
`

export const IntroInfoIcon = styled.div``

export const IntroInfoContent = styled.div``

export const IntroParagraph = styled.p`
  margin: 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left ')};

  a {
    color: ${(props) => props.color};
    font-weight: 900;

    &:hover {
      color: ${(props) => props.colorHover};
    }
  }
`

export const Link = styled.a`
  &:focus-visible {
    outline: ${({ theme }) => theme.globals.focusVisible.outline};
    box-shadow: ${({ theme }) => theme.globals.focusVisible.boxShadow};
    transition: ${({ theme }) => theme.globals.focusVisible.transition};
  }
`

export const IntroAddressParagraph = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  max-width: 400px;
  margin: 0px;
  font-family: ${(props) => props.fontFamily};
`

export const ParagraphCentered = styled.p`
  text-align: center;
`

export const HorizontalBorder = styled.div`
  width: calc(100% - 10px);
  height: 0px;
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : '1px solid #ced4dc'};
  margin: ${(props) => (props.margin ? props.margin : '0 5px 20px 5px')};
  box-sizing: border-box;
`

export const TabSwitcher = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px -30px 0 -30px;
  width: calc(100% + 60px);
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '2px solid #dbdbdb'};

  button {
    width: 160px;
    height: 36px;
    background: transparent;
    border: none;

    font-size: 14px;
    font-weight: 400;
    color: ${(props) => (props.buttonColor ? props.buttonColor : '#9b9b9b')};
    cursor: pointer;
    border-radius: 16px 16px 0 0;

    &.active {
      border-radius: 0;
      border-bottom: ${(props) =>
        props.buttonBorderBottom
          ? props.buttonBorderBottom
          : '4px solid #2a0760'};

      font-weight: 700;
      color: ${(props) =>
        props.buttonColorActive ? props.buttonColorActive : '#000'};
      cursor: default;
    }

    &:not(.active) {
      &:hover {
        background-color: #edeeef;
        color: ${(props) =>
          props.buttonColorActive ? props.buttonColorActive : '#000'};
      }
    }
  }
`

export const PopupTutorial = styled.div`
  padding-top: 30px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  img:last-child {
    margin-bottom: 80px;
  }

  div {
    color: ${(props) => (props.color ? props.color : '#34353a')};
  }

  img {
    max-width: 480px;
    margin: 10px 0 0 0;
    object-fit: contain;

    @media (max-width: 980px) {
      max-width: 100%;
      width: 100%;
    }
  }
`

export const LoadingOverlapWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const LoadingOverlap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'rgba(255, 255, 255, 0.5)'};
  z-index: 1000;
  top: 0;
  left: 0;
`

export const ConditionTitle = styled.h3<{
  required?: boolean
  asteriskColor?: string
}>`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 15px 0;

  &:after {
    color: ${(props) => props.asteriskColor};
  }

  ${(props) =>
    props.required &&
    `
    &:after {
      content: ' *';
    }
  `}
`

export const RequiredFooter = styled.div<{
  height?: string
  marginTop?: string
  fontSize?: string
  color?: string
}>`
  width: 100%;
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.height ? props.height : '20px')};
  margin-top: ${(props) => (props.marginTop ? '-' + props.marginTop : '-20px')};
  margin-bottom: 20px;
  font-weight: 500;
`

export const TestProceed = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
`

export const BoldText = styled.span`
  font-weight: 700;
  font-family: inherit;
`

export const ZoomControlWrap = styled.div`
  width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const ScrollDocumentPopupTitle = styled.h2`
  color: #25292c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.16px;
`

export const ScrollDocumentPopupBody = styled.p`
  color: #25292c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;

  &:focus-visible {
    outline: ${({ theme }) => theme.globals.focusVisible.outline};
    box-shadow: ${({ theme }) => theme.globals.focusVisible.boxShadow};
    transition: ${({ theme }) => theme.globals.focusVisible.transition};
  }
`

export const Spacer = styled.div<{ height?: string }>`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '200px')};
`

export const PdfViewerBgColorOverwrite = createGlobalStyle<{
  bgColor?: string
}>`
  body{
    background-color: ${({ bgColor }) =>
      bgColor ? bgColor : ' #fff'} !important;
  }
`
