import React, { FC } from 'react'
import { Helmet as ReactHelmet } from 'react-helmet-async'

export const Helmet: FC<{ electronicIdUrl: string }> = ({
  electronicIdUrl,
}) => {
  return (
    <ReactHelmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="/favicon-96x96.png"
        sizes="96x96"
      />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="apple-mobile-web-app-title" content="Signicat Onboarding" />

      <meta name="description" content="Onboarding Web" />
      <meta
        name="viewport"
        content={`width=device-width, initial-scale=1, maximum-scale=1, user-scalable=yes`}
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
        rel="stylesheet"
      ></link>
      {window.location.pathname.split('/')[1] === 'kontomatik' && (
        <script src="https://signin.kontomatik.com/assets/signin-widget.js"></script>
      )}

      {!!electronicIdUrl?.length && (
        <script src={`${electronicIdUrl}/js/videoid-3.x/videoid.js`} />
      )}

      <title>Signicat Onboarding Process</title>
    </ReactHelmet>
  )
}
