import styled from 'styled-components'

export const Container = styled.div<{
  hideOnMobile?: boolean
  hideOnDesktop?: boolean
  margin?: string
  marginMobile?: string
  paddingTop?: string
}>`
  width: ${(props) => (props.width ? props.width : '560px')};
  justify-content: right;
  margin: ${(props) => (props.margin ? props.margin : '0 auto')};
  padding-top: ${(props) => props.paddingTop || '15px'};
  padding-right: 15px;
  line-height: 28px;
  display: ${(props) => (props.hideOnDesktop ? 'none' : 'flex')};

  @media (max-width: 980px) {
    width: 100%;
    height: auto !important;
    min-width: auto;
    margin: ${(props) =>
      props.marginMobile ? props.marginMobile : '20px 0 0 0'};
    padding: 0 0 0 0;
    padding-right: 5px;
    box-sizing: border-box;
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`

export const Tagline = styled.span`
  font-size: 12px;
  color: rgb(149 149 149);
  margin-right: 5px;
`

export const SignicatLogo = styled.div`
  svg {
    height: 28px;
  }
`
