import React from 'react'
import { Container, SignicatLogo, Tagline } from './SignicatBranding.styles'

interface ContainerProps {
  width?: string
  hideOnDesktop?: boolean
  hideOnMobile?: boolean
  margin?: string
  marginMobile?: string
  paddingTop?: string
}

export const SignicatBranding = (props: ContainerProps) => {
  return (
    <Container
      width={props.width ? props.width : '560px'}
      hideOnDesktop={props.hideOnDesktop || false}
      hideOnMobile={props.hideOnMobile || false}
      margin={props.margin || '0 auto'}
      marginMobile={props.marginMobile || '20px 0 0 0'}
      paddingTop={props.paddingTop || '15px'}
    >
      <Tagline>Powered by: </Tagline>
      <SignicatLogo>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1054.5 572.6"
        >
          <g>
            <path
              fill="#9f9f9f"
              d="M527.2,24.3v111.9C477.1,56.1,388.2,2.8,286.7,2.8C130.2,2.8,3.3,129.8,3.3,286.3
		c0,156.5,126.9,283.5,283.5,283.5c101.4,0,190.4-53.3,240.5-133.4v111.9h524v-524H527.2z"
            ></path>
            <g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M188.9,345.7c34.4,0,57.3-20.3,57.3-47.7c0-26.1-17.5-40.1-50.3-46.6c-19.5-3.7-39.1-10.2-39.1-26.1
				c0-10.4,9.6-19.3,26.9-19.3s32.6,8.1,32.6,20.6c0,3.1-0.8,5.2-1.6,7.8l23.4,8.1c2.6-4.7,4.5-10.4,4.5-17
				c0-25.8-23.7-43.5-56.5-43.5c-32.6,0-53.9,20.3-53.9,43.2c0,29.2,21.6,43,55.2,48.5c25,4.5,32.8,13,32.8,25
				c0,13-11.7,22.7-31,22.7c-17.9,0-34.4-7.8-34.4-23.7c0-2.6,0.5-5.5,1.3-8.6l-23.4-9.6c-2.4,4.5-4.5,10.9-4.5,17.5
				C128.4,326.4,151.4,345.7,188.9,345.7"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M264.8,204.5c0,8.6,6.5,15.1,15.1,15.1c8.6,0,15.4-6.5,15.4-15.1c0-8.6-6.7-15.4-15.4-15.4
				C271.4,189.1,264.8,195.9,264.8,204.5"
                ></path>
              </g>
              <rect
                x="268.2"
                y="235.7"
                fill="#FFFFFF"
                width="23.7"
                height="107.6"
              ></rect>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M365.7,322.8c-17.5,0-31-12.2-31-32.8c0-22.7,13.6-36.2,31-36.2c17.7,0,31,14.6,31,34.6
				C396.6,308.5,383.1,322.8,365.7,322.8 M395.6,235.5v14.6c-6.7-10.2-17-16.7-34.1-16.7c-28.4,0-51.1,22.4-51.1,56.5
				c0,32.8,21.6,53.4,48.7,53.4c18.2,0,30.7-7.8,36.2-19.3v11.5c0,20.3-7.8,34.6-28.4,34.6c-14.9,0-26.1-7.5-29.5-22.1l-21.6,9.1
				c7.8,23.7,26.1,33.6,51.1,33.6c34.1,0,52.1-23.4,52.1-56.3v-99H395.6z"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M467.5,283.2c0-19,11.5-29.5,25.5-29.5c13.3,0,22.1,9.6,22.1,27.4v62.3h23.7V273c0-25.3-15.9-39.6-37.8-39.6
				c-13.3,0-25.8,5-33.9,17.9v-15.7h-23.7v107.6h23.7v-60.2L467.5,283.2L467.5,283.2z"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M628.3,288.7c0-23.7,17-34.9,31.8-34.9c15.9,0,27.4,9.9,29.5,25.8l21.6-6.7c-4.5-25-25-39.6-51.1-39.6
				c-29.5,0-54.4,22.4-54.4,55.5c0,34.1,22.7,56.8,57.8,56.8c26.1,0,47.7-14.9,53.4-43.2l-21.6-7.8c-2.4,15.9-11.5,30.7-32.8,30.7
				C645.5,325.2,628.3,311.3,628.3,288.7"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M783.3,324.3c-17,0-32-13.3-32-34.4s14.9-35.2,32-35.2c15.9,0,32.3,13.3,32.3,35.2
				C815.3,311.8,799,324.3,783.3,324.3 M814.6,235.4v17.5c-6.2-12.2-19-19.8-36.2-19.8c-29.9,0-50.8,23.7-50.8,56.8
				c0,31.8,21.4,55.5,50.8,55.5c16.4,0,28.9-6.7,37.5-20.3v18.2h22.7V235.4H814.6z"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M913.6,345.5c20.3,0,34.1-12.5,36.2-34.1l-22.7-4.5c-0.8,12.5-5.8,18.2-14.9,18.2c-6.7,0-13.6-4.5-13.6-14.9
				v-54.2h40.9v-20.6h-40.9v-34.4H875v34.4h-21.6v20.3H875V309C874.8,330.6,887.3,345.5,913.6,345.5"
                ></path>
              </g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M559.9,204.5c0,8.6,6.5,15.1,15.1,15.1c8.6,0,15.4-6.5,15.4-15.1c0-8.6-6.7-15.4-15.4-15.4
				C566.4,189.1,559.9,195.9,559.9,204.5"
                ></path>
              </g>
              <rect
                x="563.3"
                y="235.7"
                fill="#FFFFFF"
                width="23.7"
                height="107.6"
              ></rect>
            </g>
          </g>
        </svg>
      </SignicatLogo>
    </Container>
  )
}
