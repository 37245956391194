import styled, { createGlobalStyle } from 'styled-components'

interface InputStyleProps {
  checkboxColorActive?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  container?: any
  checkboxColor?: string
  checkboxBorderRadius?: string
  focusVisible?: {
    outline: string
    boxShadow: string
    transition: string
  }
}

export const InputStyle = createGlobalStyle<InputStyleProps>`
  body{
    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    input:checked + .label-text:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : 'black'};
      border: ${(props) =>
        props.checkboxColorActive
          ? `${props.checkboxColorActive} 2px solid !important`
          : 'gray 2px solid !important'};
    }

    input:checked + .label-text:after {
      content: '';
      display: block;
      position: absolute;
      background-image: ${(props) =>
        `url('data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20viewBox%3D%220%200%2012%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M11.3692%200.466489C11.6638%200.780755%2011.6479%201.27437%2011.3337%201.569L4.93388%207.569C4.63386%207.85029%204.16698%207.8503%203.86694%207.56903L0.666715%204.56903C0.352433%204.27441%200.336492%203.7808%200.63111%203.46652C0.925729%203.15224%201.41934%203.1363%201.73362%203.43092L4.40037%205.93081L10.2667%200.430944C10.581%200.136309%2011.0746%200.152223%2011.3692%200.466489Z%22%20fill%3D%22${encodeURIComponent(
          props.container.bgColor ? props.container.bgColor : 'white'
        )}%22%2F%3E%3C%2Fsvg%3E')`};
      top: 7px;
      left: 4.5px;
      width: 11px;
      height: 8px;
    }

    .label-text {
      position: relative;
      display: flex;
    }

    .label-text:before {
      width: 20px;
      height: 20px;
      content:'';
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border: ${(props) =>
        props.checkboxColor
          ? `${props.checkboxColor} 2px solid !important`
          : 'gray 2px solid !important'};
          border-radius: ${(props) =>
            props.checkboxBorderRadius ? `${props.checkboxBorderRadius}` : '0'};
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 1px;
      box-sizing: border-box;

      &:focus-visible {
        outline: ${(props) => props.focusVisible.outline};
        box-shadow: ${(props) => props.focusVisible.boxShadow};
        transition: ${(props) => props.focusVisible.transition};
      }
    }
  }
`

export const TickAllStyle = styled.div<{
  padding: string
  margin: string
  minWidth: string
  width: string
  widthMobile: string
  height: string
  heightMobile: string
  fontSize: string
  fontSizeMobile: string
  fontWeight: string
  textDecoration: string
  paddingMobile?: string
  marginMobile?: string
}>`
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '20px 10px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : 'none'};

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 980px) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : '16px'};
    width: ${(props) => (props.widthMobile ? props.widthMobile : '100%')};
    height: ${(props) => (props.heightMobile ? props.heightMobile : 'auto')};
    padding: ${(props) =>
      props.paddingMobile ? props.paddingMobile : '10px 15px'};
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '0')};
  }
`
