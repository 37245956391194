import { useEffect, useState } from 'react'
import { Document } from '@components/SignDocument/types'
import { axiosInstance } from '@/methods/axiosConfig'
import { apiUrl } from '@/constants/apiUrl'
import { useInterval } from 'usehooks-ts'
import { actionReport } from '@/methods/actionReport'
import { devLog } from '@/methods/devLog'

interface PollingResponse {
  status: 'ready' | 'not-ready'
}

export const useSignDocumentPolling = (
  currentScenarioId: string,
  pollingInterval: number
) => {
  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  const [singleDoc, setSingleDoc] = useState<{
    url: string
    id: string
  }>()
  const [docs, setDocs] = useState<Document[]>([])
  const [isDocReady, setIsDocReady] = useState(false)
  const [shouldPoll, setShouldPoll] = useState(true)
  const [isChecking, setIsChecking] = useState(false)

  const getDocument = async () => {
    setIsLoading(true)
    try {
      const res = await axiosInstance.get<Document[]>(
        `${apiUrl}/electronic-id/list-of-documents`,
        {
          withCredentials: true,
        }
      )
      devLog(res.data)

      if (res.data.length === 1) {
        const { data } = await axiosInstance.get<{ signedUrl: string }>(
          `/electronic-id/pdf-document-presigned`,
          {
            withCredentials: true,
          }
        )

        setSingleDoc({
          url: data.signedUrl,
          id: res.data[0].fileId,
        })
      } else if (res.data.length > 1) {
        setDocs(res.data)
      }

      setIsDocReady(true)

      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_ACKNOWLEDGED',
        payload: {},
      })
    } catch (e) {
      devLog(e)
    }
    setIsLoading(false)
  }

  const handleSign = async (docId: string): Promise<boolean> => {
    try {
      if (currentScenarioId !== 'localTests') {
        setBtnLoading(true)
        const apiUrl = process.env.WEB_API_URL

        await axiosInstance.post(
          `${apiUrl}/electronic-id/accept-document-to-be-signed`,
          {
            fileId: docId,
          },
          {
            withCredentials: true,
          }
        )

        setBtnLoading(false)
      }

      return true
    } catch (e) {
      devLog(e)
      setBtnLoading(false)
      return false
    }
  }

  const checkStatus = async (): Promise<PollingResponse | undefined> => {
    setIsChecking(true)

    try {
      const res = await axiosInstance.get<PollingResponse>(
        `${apiUrl}/electronic-id/are-documents-to-be-signed-ready-to-display`,
        {
          withCredentials: true,
        }
      )

      if (res.data.status && res.data.status === 'ready') {
        setShouldPoll(false)
      }
      return res.data
    } catch (e) {
      // Ideally this should be a better error handling
      console.error(e)
    } finally {
      setIsChecking(false)
    }
  }

  useEffect(() => {
    if (!shouldPoll) {
      getDocument()
    }
  }, [shouldPoll])

  useInterval(
    () => {
      if (!isChecking) {
        checkStatus()
      }
    },
    shouldPoll ? pollingInterval : null
  )

  return {
    isLoading,
    isDocReady,
    singleDoc,
    docs,
    handleSign,
    btnLoading,
  }
}
