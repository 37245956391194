export function getViewerHeight(
  screenSize: { width: number; height: number },
  headerHeight: number = 0,
  footerHeight: number = 0
) {
  const minViewerHeight = 320
  const minDynamicViewerHeight = 180

  const removeHeight = headerHeight + footerHeight

  const flatHeightToReduce = 240

  if (screenSize.width <= 320 && screenSize.height <= 568) {
    //mobile portrait
    if (screenSize.height - removeHeight < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${headerHeight}px - 80px - ${footerHeight}px)`
  } else if (screenSize.width <= 568 && screenSize.height <= 320) {
    //mobile landscape
    if (screenSize.height - removeHeight < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${headerHeight}px - 80px - ${footerHeight}px)`
  } else if (screenSize.width <= 600 && screenSize.height <= 960) {
    // small tablet portrait
    if (screenSize.height - removeHeight < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${headerHeight}px - 80px - ${footerHeight}px)`
  } else if (screenSize.width <= 980 && screenSize.height <= 1024) {
    // tablet portrait
    if (screenSize.height - removeHeight < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${headerHeight}px - 80px - ${footerHeight}px)`
  } else if (screenSize.width <= 1024 && screenSize.height <= 768) {
    // tablet landscape
    if (screenSize.height - 400 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return 'calc(100dvh - 400px)'
  } else if (screenSize.width <= 1280 && screenSize.height <= 800) {
    // small desktop
    if (screenSize.height - 400 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return 'calc(100dvh - 400px)'
  } else if (screenSize.width <= 1366 && screenSize.height <= 768) {
    // medium desktop
    if (screenSize.height - 400 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return 'calc(100dvh - 400px)'
  } else if (screenSize.width <= 1440 && screenSize.height <= 900) {
    // large desktop
    if (screenSize.height - 450 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${flatHeightToReduce}px)`
  } else if (screenSize.width <= 1920 && screenSize.height <= 1080) {
    // extra large desktop
    if (screenSize.height - 450 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${flatHeightToReduce}px)`
  } else {
    // alice in wonderlands
    if (screenSize.height - 450 < minDynamicViewerHeight) {
      return minViewerHeight
    }

    return `calc(100dvh - ${flatHeightToReduce}px)`
  }
}
